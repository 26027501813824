/* Base styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom variables */
:root {
  --bs-background: #806743;
  --bs-primary: #BCA37F;
  --bs-secondary: #EAD7BB;
  --bs-tertiary: #FFF2D8;
  --bs-accent: #4d391d;
  --transition-speed: 0.3s;
  --box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

/* Navigation */
.navbar-nav li a {
  color: var(--bs-tertiary) !important;
}

/* Text utilities */
.text-tertiary {
  color: var(--bs-tertiary) !important;
}

.text-accent {
  color: var(--bs-accent) !important;
}

/* Background utilities */
.bg-background { background-color: var(--bs-background) !important; }
.bg-accent { background-color: var(--bs-accent) !important; }
.bg-primary { background-color: var(--bs-primary) !important; }
.bg-secondary { background-color: var(--bs-secondary) !important; }
.bg-tertiary { background-color: var(--bs-tertiary) !important; }

/* Button styles */
.btn.btn-primary {
  color: #fff !important;
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary.active {
  color: #fff !important;
  background-color: var(--bs-background) !important;
  border-color: var(--bs-accent) !important;
}

.btn-light {
  --bs-btn-color: var(--bs-accent);
  --bs-btn-bg: var(--bs-tertiary);
  --bs-btn-border-color: var(--bs-tertiary);
  --bs-btn-hover-color: var(--bs-accent);
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-focus-shadow-rgb: 211,212,213;
  --bs-btn-active-color: var(--bs-accent);
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-active-shadow: var(--box-shadow);
  --bs-btn-disabled-color: var(--bs-accent);
  --bs-btn-disabled-bg: var(--bs-tertiary);
  --bs-btn-disabled-border-color: var(--bs-tertiary);
}

.btn-light:hover {
  transform: scale(1.05);
  transition: transform var(--transition-speed) ease;
}

.bg-secondary {
  background-color: var(--bs-secondary) !important;
}
.btn-secondary {
  color: #fff;
  background-color: var(--bs-secondary);
  border-color: var(--bs-secondary);
}
.btn-secondary:hover {
  color: #fff;
  background-color: #806743;
  border-color: #4d391d;
}

/* Component styles */
.moodboard {
  border: 10px solid;
  border-radius: 25px;
  padding: 20px;
  margin: auto;
}

.photo {
  border: 5px solid white;
}

/* Animations */
@keyframes gradientBg {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.animate-gradient {
  background: linear-gradient(270deg, 
    var(--bs-background), 
    var(--bs-primary), 
    var(--bs-secondary));
  background-size: 200% 200%;
  animation: gradientBg 15s ease infinite;
}